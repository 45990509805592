<template>
    <div class="p-servers">
        <div class="hd">
            <div class="page-title">服务器</div>
        </div>
        <div class="bd">
            <router-view></router-view>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less">
.p-servers {
    & > .bd {
        padding-top: 10px;
    }
}
</style>

<script>
export default {
    components: {},
    data() {
        return {
            ready: false,
            isLoading: false,
        };
    },
    computed: {},
    mounted() {},
    methods: {},
    filters: {},
};
</script>
